import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
//DATA
import { info, godziny } from "../assets/data/kontakt"
//STYLES
import { H1, H4, Text, LabelBW } from "../utils/typo"
import { Section2, ContainerMob } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/Seo"

const Baner = styled.div`
  position: absolute;
  width: 100%;
  max-width: 2560px;
  height: 380px;
  overflow: hidden;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  ${down("md")} {
    height: 360px;
  }

  ${down("sm")} {
    height: 240px;
  }

  ${down("xs")} {
    height: 240px;
    > div {
      height: 240px;
    }
  }

  :before {
    content: "";
    background: rgb(0, 46, 12);
    background: linear-gradient(
      180deg,
      rgba(0, 46, 12, 0.85) 0%,
      rgba(24, 77, 0, 0.75) 50%,
      rgba(96, 143, 11, 0.65) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 2;
  }
`

const Info = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7rem;

  ${down("sm")} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  p {
    white-space: pre-wrap;
  }

  iframe {
    //filter: grayscale(100%);
    ${down("sm")} {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  b {
    margin-right: 1rem;
  }
`

const MapMobile = styled.div`
  margin-top: 4rem;
  > div {
    height: 300px;
  }

  iframe {
    //filter: grayscale(100%);
    height: 300px;
  }

  ${up("md")} {
    display: none;
  }
`

const Kontakt = () => {
  return (
    <div>
      <Seo title="Kontakt" slug="/kontakt" />
      <Baner>
        <StaticImage
          src="../assets/images/6.jpg"
          alt="Szkółka drzew z lotu ptaka"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
        />
      </Baner>
      <ContainerMob mt="4rem" mtMobile=".5rem">
        <H1 colorFont="white" textAlign="center">
          Zostańmy w kontakcie!
        </H1>
        <Text colorFont="white" textAlign="center">
          Zapraszamy do odwiedzenia naszej szkółki w Pszczynie <br /> codziennie
          od{" "}
          <b>
            {godziny[2].godzinaOd} do {godziny[2].godzinaDo}
          </b>
          , a w soboty od {godziny[5].godzinaOd} do {godziny[5].godzinaDo}.
          <br /> Czekamy na Ciebie!
        </Text>
      </ContainerMob>
      <Section2
        pt={6}
        pb={3}
        tabIndex="0"
        role="contentinfo"
        ariaLabel="Dane kontaktowe i mapa dojazdu"
      >
        <ContainerMob>
          <Info>
            <iframe
              title="map"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=18.95487070083618%2C49.95783977359637%2C18.969032764434818%2C49.964120555789364&amp;layer=mapnik&amp;marker=49.960980267116625%2C18.961951732635498"
            />

            <TextWrapper>
              <LabelBW>Kontakt</LabelBW>
              <H4 mt="2rem">+48 {info.tel}</H4>
              <H4>{info.mail}</H4>

              <LabelBW mt="3rem">Adres</LabelBW>
              <p>
                <b>{info.wydzial}</b>
              </p>
              <p>
                ul. {info.ulica} {info.nrBud}
              </p>
              <p>
                {info.kod} {info.miasto}
              </p>
              <p>{info.woj}</p>
              <p>{info.kraj}</p>

              <LabelBW mb="1rem" mt="3rem">
                Godziny otwarcia
              </LabelBW>
              {godziny.map(godzina => (
                <p>
                  <b>{godzina.day}: </b>
                  {godzina.godzinaOd} - {godzina.godzinaDo}
                </p>
              ))}
            </TextWrapper>
          </Info>

          <MapMobile>
            <iframe
              title="mapMobile"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.openstreetmap.org/export/embed.html?bbox=18.95487070083618%2C49.95783977359637%2C18.969032764434818%2C49.964120555789364&amp;layer=mapnik&amp;marker=49.960980267116625%2C18.961951732635498"
            />
          </MapMobile>
        </ContainerMob>
      </Section2>
    </div>
  )
}
export default Kontakt
